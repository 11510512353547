.solar-system {
    position: relative;
    width: 100%;
    height: 500px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    background-color: var(--primary-color);
}

.orbit {
    border: 1px dashed #959393;
}

#sun,
.orbit,
.orbit+div,
.orbit+div>div {
    position: absolute;
}

#sun {
    overflow: hidden;
}

#sun,
.orbit,
.orbit+div {
    top: 50%;
    left: 50%;
}

.orbit+div>div {
    top: 0;
    left: 50%;
}

.orbit,
.orbit+div div,
#earth {
    border-radius: 50%;
}

#sun {
    height: 150px;
    width: 150px;
    background-color: var(--sun-changer);
    margin-top: -80px;
    margin-left: -80px;
    border-radius: 50%;
    box-shadow: 0 0 84px var(--sun-changer);
}

#earth {
    position: absolute;
    top: 0;
    left: 50%;
    height: 70px;
    width: 70px;
    margin-left: -36px;
    margin-top: -36px;
}

.earth-orbit,
.earth-spin {
    width: 350px;
    height: 350px;
    margin-left: -180px;
    margin-top: -180px;
}

#moon {
    margin-top: -10px;
    margin-left: -10px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: white;
    box-shadow: 0 0 14px #3a4385;
}

.moon-orbit,
.moon-spin {
    height: 96px;
    width: 96px;
    margin-top: -48px;
    margin-left: -48px;
    border-color: #447;
}

#venus {
    margin-top: -20px;
    margin-left: -20px;
    height: 40px;
    width: 40px;
    box-shadow: 0 0 34px #3a4385;
}

#venus img {
    border-radius: 150%;
}

.venus-orbit,
.venus-spin {
    margin-top: -115px;
    margin-left: -115px;
    width: 220px;
    height: 220px;
}

#mars {
    margin-top: -22px;
    margin-left: -22px;
    height: 50px;
    width: 50px;
    box-shadow: 0 0 14px #a64232;
}

#mars img {
    border-radius: 50%;
}

.mars-orbit,
.mars-spin {
    width: 470px;
    height: 470px;
    margin-left: -240px;
    margin-top: -240px;
}

@keyframes spin-right {
    100% {
        transform: rotate(360deg);
    }
}

.earth-spin {
    animation: spin-right 36.5s linear infinite;
}

.moon-spin {
    animation: spin-right 7s linear infinite;
}

.venus-spin {
    animation: spin-right 22s linear infinite;
}

.mercury-spin {
    animation: spin-right 2.8s linear infinite;
}

.mars-spin {
    animation: spin-right 68.7s linear infinite;
}