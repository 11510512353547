@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-background-color: #ffffff;
  --color-changer: #0487fb;
  --sun-changer: #fae20a;
  --primary-color: #121215;

}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.btn-bg-color {
  background-color: var(--color-changer);
}










.darkmode-toggle {
  background-color: var(--color-changer);

  width: 3rem;
  height: 3rem;
  position: fixed;
  right: 0px;
  z-index: 999;
  bottom: 180px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;


}

.sunny-dark {
  color: rgb(26, 6, 6);
}













/* ================================
===         Home              =====
=================================== */









.home-div {
  display: flex;
  margin-top: 1px;
}

.welcome-img img {
  width: 300px;
}

.name-div {
  height: 500px;
  width: 50%;
  background-color: var(--primary-color);

}

.home-div-main {
  position: relative;
  background-color: var(--primary-color);
}

#home .home-div-main .home-div .pic-div {
  height: 500px;
  width: 50%;
  background-color: var(--primary-color);
}

.name-div {
  padding-top: 70px;
  padding-left: 30px;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");

.name-div22 {
  line-height: 0;
  margin-top: 30px;
  /* font-family: 'Roboto', sans-serif;
  font-family: "Poppins", sans-serif; */
}

.typical {
  font-size: 20px;
  color: #C0C0C0;
}

.name-div .name-p {
  color: white;
}

.name-div img {
  width: 80px;
}

.name-div hr {
  color: #a8a7a7;
}

.name-div h1 {
  color: hsl(0, 0%, 74%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  /* font-family: "Montserrat", sans-serif; */
  font-weight: 700;
  cursor: pointer;
}

.name-div h1 {
  /* background: linear-gradient(to right, hsl(0, 0%, 30%) 0, hsl(0, 0%, 100%) 10%, hsl(0, 0%, 30%) 20%); */
  background-image: -webkit-linear-gradient(45deg, hsl(214, 100%, 54%), hsl(34, 100%, 54%));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 4s infinite linear;
  /* font-family: "Montserrat", sans-serif; */
}

.gradient-line1 {
  height: 5px;
  background: linear-gradient(to right,
      rgba(0, 1, 1, 1),
      var(--color-changer));
}

.gradient-line0 {
  border-radius: 50%;

}

.gradient-line0 img {
  width: 30px;
  box-shadow: 0 0 34px #7b8afd;
  border-radius: 50%;
  background: rgba(123, 138, 253, 0.6);
}

.gradient-line0,
.coding-icon34 {
  display: inline-block;
  color: var(--color-changer);
}

.coding-icon34 {
  display: block;
}

.gradient-line {
  height: 5px;
  background: linear-gradient(to left,
      rgba(0, 1, 1, 1),
      var(--color-changer));

}

.social-link {
  display: flex;
}

.name-div .typical {
  font-weight: 700;
  /* font-family: "Montserrat", sans-serif; */
}

.name-div p {
  /* font-family: "Montserrat", sans-serif; */
}

.social-link FontAwesomeIcon {
  width: 100px;
  color: #EBF1F8;
}

.social-link div img {
  color: white;
}

.social-link .icon-main {
  color: var(--color-changer);
}

.icon-div {
  display: flex;
}



.pic-div img {
  width: 500px;
}

.wave1 img {
  position: absolute;
}

.wave1 {
  top: 1000px;
}


/*#################################################
####                                            ####
####      Short Introduction         css        ####
####                                            ####
##################################################*/

.sort-intro {
  height: 400px;
  width: 800px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.sort-intro-headline {
  display: flex;
  background-color: #EBF1F8;
}

.sort-intro-img img {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.sort-intro-head {
  padding: 10px;
  height: 40px;
  width: 500px;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-left: auto;
  margin-right: auto;
  clip-path: polygon(75% 0%, 84% 50%, 75% 100%, 0% 100%, 5% 50%, 0% 0%);
}

.sort-intro-headline .sort-intro-head img {
  width: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.linkedin-follow img {
  right: 0;
  padding-right: 7px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.sort-intro-headline img {
  width: 150px;
}

.sort-intro-child {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 7pxpx;
  background-color: #EBF1F8;
  box-shadow: 4px 4px 8px 2px rgba(0, 0, 0, 0.3);
}

.sort-intro-child-div {
  height: 120px;
  width: 365px;
  border-radius: 5px;
  line-height: 0.5;
}

.sort-intro-child-div img {
  width: 80px;
  margin: 20px;
}

.institution {
  margin-left: 130px;
  /* font-family: sans-serif; */
}

.institution33 {
  margin-left: 130px;
  /* font-family: sans-serif; */
  font-size: 15px;
  color: #3a4251;
}

.institution44 {
  margin-left: 130px;
  /* font-family: sans-serif; */
  font-size: 14px;
  color: #727B8A;
}

.institution11 {
  font-size: 23px;
  margin-left: 130px;
  /* font-family: 'Times New Roman', Times, serif; */
  font-weight: 700;
  color: #504848;
  /* font-family: 'Times New Roman', Times, serif; */
}











/* ================================
===       Shortintro           =====
=================================== */





.sort-intro {
  height: 400px;
  width: 800px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.marquee-div {
  background-color: var(--color-changer);
  color: white;
}

.sort-intro-headline {
  display: flex;
  justify-content: space-between;
  background-color: #EBF1F8;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.sort-intro-headline .sort-intro-head img {
  width: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.sort-intro-headline img {
  width: 150px;
}

.sort-intro-img img {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

@keyframes beatAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.sort-intro-img img {
  animation: beatAnimation 2s ease-in-out infinite;
}

.wave1 .sort-intro .sort-intro-headline .sort-intro-head {
  padding: 10px;
  height: 40px;
  width: 500px;
  background-color: rgb(255, 255, 255);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-left: auto;
  margin-right: auto;
}

.linkedin-follow img {
  right: 0;
  padding-right: 7px;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.sort-intro-child {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.sort-intro-child-div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 120px;
  width: 365px;
  border-radius: 5px;
  line-height: 0.5;
}

.sort-intro-child-div .sort-intro-div-img23 {
  width: 120px;
}

.sort-intro-child-div .sort-intro-div-descrip234 {
  width: 250px;
}

.sort-intro-div-descrip234 p {
  margin-left: 0px;
  margin-top: 20px;
  padding-left: 10px;
}

.sort-intro-child-div img {
  width: 80px;
  margin: 20px;
}

.institution {
  margin-left: 130px;
  color: #727B8A;
}

.institution33 {
  margin-left: 130px;

  font-size: 13px;
  color: #3a4251;
}

.institution44 {
  margin-left: 130px;

  font-size: 14px;
  color: #727B8A;
}

.institution11 {
  font-size: 17px;
  margin-left: 130px;
  color: #504848;
  font-family: "Montserrat", sans-serif;
}









/* ================================
===       About short         =====
=================================== */








.sortabout {
  width: 68%;
  margin-left: auto;
}

.short-intro-marquee {
  background-color: var(--color-changer);
}

.sort-intro-child11 {
  height: 80px;
  width: 95%;
  border-radius: 5px;
  display: flex;
  background-color: #E7F4FD;
}

.sort-intro-child11:hover {
  transition: 0.1ms;
  background-color: var(--color-changer);
  color: white;
}

.sort-intro-child11:hover .email-section h5,
.sort-intro-child11:hover .email-section p {
  color: #E7F4FD;
}


.sort-intro-child-main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.sort-intro-child11 img {

  width: 80px;
}

.sort-intro-child11 .circle1 {
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 70px;
  width: 70px;
  padding: 0px;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;

}

.email-section {
  line-height: 0.5;
}

.email-section h5 {
  color: #432323;

}

.email-section p {
  color: #2E1818;

}

.circle1 .icon {
  width: 20px;
  color: var(--color-changer);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.sort-introimg img {
  width: 170px;
  border-radius: 5px;

}

.followgithub img {
  width: 170px;
  right: 0;
  padding-right: 7px;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.sort-intro-he {
  justify-content: space-between;
  display: flex;
  background-color: #EBF1F8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

}

.sort-intro-he .sort-intro-head11 img {

  width: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.sort-intro-head11 {

  padding: 10px;
  height: 40px;
  width: 500px;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  margin-left: auto;
  margin-right: auto;
}

/* Define keyframes for the beat animation */
@keyframes beatAnimation {
  0% {
    transform: scale(1);
    /* Start scale */
  }

  50% {
    transform: scale(1.2);
    /* Scale up */
  }

  100% {
    transform: scale(1);
    /* Return to normal scale */
  }
}

/* Apply the animation to the image */
.sort-introimg img {
  animation: beatAnimation 2s ease-in-out infinite;
  /* Adjust duration, timing, and iteration */
}








/* ================================
===       About                =====
=================================== */














.about-section {
  height: 540px;
  width: 100%;
  border-radius: 5px;
  margin-top: 220px;
  position: absolute;
  background-color: var(--primary-color);
}

.about-section h1 {
  font-size: 25px;
  position: absolute;
  margin-top: 180px;
  margin-left: 30px;
  color: #d1abfd;
  font-weight: 700;
  /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
}

.about-section .about-para-about {
  font-size: 15px;
  /* font-family: "Montserrat", sans-serif; */
  position: absolute;
  margin-top: 210px;
  margin-left: 30px;
  color: #d3c2e6;
}

.about-section .about-detils {
  color: #e8e8e8;
  margin-left: 30px;
  margin-top: 20px;

  text-align: justify;
  text-justify: inter-word;
  letter-spacing: 0.5px;
}

.about-section .about-detilsold {
  color: #e8e8e8;
  margin-left: 30px;
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  text-align: justify;
  text-justify: inter-word;
  letter-spacing: 0.5px;
}

.about-section button {
  background-color: white;
  padding: 5px;
  width: 120px;
  border-radius: 20px;
  font-weight: 700;
  color: black;
  margin-left: auto;
  margin-right: auto;
}

.about-section .resume {
  margin-top: 25px;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.about-section .resume button {
  background-color: var(--color-changer);
  color: white;
  font-weight: 500;
}

.about-section .resume button:hover {
  background-color: #016fcf;
  color: white;
  transition: 0.5s;
}

.mainimg {
  position: relative;
  margin-top: 0px;
  width: 390px;
}

.about {
  height: 300px;
  width: 300px;
  border-radius: 10px;
  background-color: aliceblue;
}

.yearexperience {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  background-color: black;
  border-radius: 5px;
}

.yearexperience h3 {
  color: #e8e8e8;
  font-weight: 700;
}

.yearexperience p {
  color: #A0A0A0;
  font-weight: 700;
  font-size: 18px;
}

.yearexperience .years {
  height: 100px;
  width: 100%;
  background-color: #122136;
  border-right: 1px solid white;
  line-height: 10px;
}

.yearexperience .years1 {
  height: 100px;
  width: 100%;
  background-color: #122136;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  line-height: 10px;
}

.yearexperience .years11 {
  height: 100px;
  width: 100%;
  border-right: 1px solid white;
  background-color: #122136;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  line-height: 10px;
}










/* ================================
===       Skillsbar           =====
=================================== */

.whatsapplogo {
  color: var(--color-changer);
}

.goto-top {
  background-color: var(--color-changer);
}

.chart a button {

  border-radius: 20px;
  width: 100px;
  padding: 5px;
  color: white;
  font-weight: 700;
  text-decoration: none;
}

.chart a {
  margin-top: 20px;
  margin-left: 30%;
}




.github-btn {
  background-color: var(--color-changer);
}

.skillsbar {
  margin-top: 170px;
}

.skills-marque {
  background-color: var(--color-changer);
}

.skills-bar-head {
  justify-content: space-between;
  display: flex;
  background-color: #EBF1F8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.skills-bar-head11 img {
  width: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.skills-bar-head11 {
  padding: 10px;
  height: 40px;
  width: 700px;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 3px;
  margin-left: auto;
  margin-right: auto;
  clip-path: polygon(75% 0%, 84% 50%, 75% 100%, 0% 100%, 5% 50%, 0% 0%);
}

.skills-introimg img {
  width: 170px;
  border-radius: 5px;
}

@keyframes beatAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.skills-introimg img {
  animation: beatAnimation 2s ease-in-out infinite;
}

.followglinkedin img {
  width: 170px;
  border-radius: 5px;
}

.skills-child-main {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 500px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.lastupdate {
  border: 1px solid rgb(152, 152, 152);
  border-radius: 5px;
  padding: 3px;
  font-size: 12px;
}

.skills-headline-child {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

.skills-headline-child div {
  height: 100px;
  width: 100%;
  background-color: var(--color-changer);
  border-radius: 5px;
  line-height: 0.4;
  padding: 15px;
  padding-top: 20px;
}

.skills-headline-child div .dsahead {
  font-weight: 700;
  color: white;
  font-size: 18px;
}

.skills-headline-child div .dsahead1 {
  font-weight: 700;
  color: #d1def4;
  font-size: 14px;
}

.bar-chart {
  display: flex;
}

.pro-barchart {
  height: 300px;
  width: 32%;
}

.language-barchart {
  width: 100%;
  display: grid;
  grid-gap: 7px;
  grid-template-columns: repeat(2, 1fr);
}

.bar-chart-child {
  height: 300px;
  width: 100%;
  background-color: #EBF1F8;
  border-radius: 5px;
}

.benest {
  display: flex;
  height: 200px;
  width: 100%;
  background-color: black;
  margin-top: 200px;
  border-radius: 30px;
}

.benext-child {
  height: 100%;
  width: 50%;
  background-color: var(--primary-color);
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}

.asasas {
  position: absolute;
  width: 300px;
  margin-top: 100px;
}

.benext-child2 {
  height: 100%;
  width: 100%;
  background-color: var(--primary-color);
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
}

.benext-child2 h1 {
  color: white;
  font-size: 25px;
  font-weight: 700;
}

.benext-child2 p {
  margin-top: 15px;
  color: white;
  font-size: 16px;

}

.benext-child2 button {
  margin-top: 15px;
  font-family: 'Times New Roman', Times, serif;
}









/* ================================
===       Certificate           =====
=================================== */







.certificate-flex-box {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
}

.certificate-flex-box .shad12 {
  flex: 0 0 auto;
  padding: 5px;
}

#slideshow {
  margin: 0 auto;
  height: 300px;
  width: 100%;
  box-sizing: border-box;
}

.slideshow-title {
  font-family: 'Allerta Stencil';
  font-size: 62px;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  margin-top: 25%;
  letter-spacing: 3px;
  font-weight: 300;
}

.sub-heading {
  padding-top: 50px;
  font-size: 18px;
}

.sub-heading-two {
  font-size: 15px;
}

.sub-heading-three {
  font-size: 13px;
}

.sub-heading-four {
  font-size: 11px;
}

.sub-heading-five {
  font-size: 9px;
}

.sub-heading-six {
  font-size: 7px;
}

.sub-heading-seven {
  font-size: 5px;
}

.sub-heading-eight {
  font-size: 3px;
}

.sub-heading-nine {
  font-size: 1px;
}

.entire-content {
  margin: auto;
  width: 190px;
  perspective: 1000px;
  position: relative;
  padding-top: 80px;
}

.content-carrousel {
  width: 100%;
  position: absolute;
  float: right;
  animation: rotar 60s infinite linear;
  transform-style: preserve-3d;
}

.content-carrousel:hover {
  animation-play-state: paused;
  cursor: pointer;
}

.content-carrousel figure {
  width: 100%;
  height: 120px;
  border: 1px solid #3b444b;
  overflow: hidden;
  position: absolute;
}

.content-carrousel figure:nth-child(1) {
  transform: rotateY(0deg) translateZ(300px);
}

.content-carrousel figure:nth-child(2) {
  transform: rotateY(40deg) translateZ(300px);
}

.content-carrousel figure:nth-child(3) {
  transform: rotateY(80deg) translateZ(300px);
}

.content-carrousel figure:nth-child(4) {
  transform: rotateY(120deg) translateZ(300px);
}

.content-carrousel figure:nth-child(5) {
  transform: rotateY(160deg) translateZ(300px);
}

.content-carrousel figure:nth-child(6) {
  transform: rotateY(200deg) translateZ(300px);
}

.content-carrousel figure:nth-child(7) {
  transform: rotateY(240deg) translateZ(300px);
}

.content-carrousel figure:nth-child(8) {
  transform: rotateY(280deg) translateZ(300px);
}

.content-carrousel figure:nth-child(9) {
  transform: rotateY(320deg) translateZ(300px);
}

.content-carrousel figure:nth-child(10) {
  transform: rotateY(360deg) translateZ(300px);
}

.shadow {
  position: absolute;
  box-shadow: 0px 0px 20px 0px #000;
  border-radius: 1px;
}

.content-carrousel img {
  image-rendering: auto;
  transition: all 300ms;
  width: 100%;
  height: 100%;
}

.content-carrousel img:hover {
  transform: scale(1.2);
  transition: all 300ms;
}

@keyframes rotar {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

.certification-main {
  background-color: var(--primary-color);
  padding-bottom: 10px;
  margin-top: 100px;
  position: relative;
}

.certi-div {
  height: 80px;
  /* background-color: #13111c; */
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.certi-div:hover {
  border-top: 1px solid blue;
  border-bottom: 1px solid blue;
  transition: 1s;
}

.certi-div img {
  width: 120px;
}

#upgrade1 img {
  width: 200px;
}

.certi-div img {
  margin: 20px;
  padding: 10px;
}

.certi-descrip h1 {
  font-size: 20px;
  color: #00ffd5;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.certificate-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.certificate-img h1 {
  color: #fff;
  font-weight: 700;
  font-size: 26px;
  font-family: 'Times New Roman', Times, serif;
}

.certificate-img img {
  width: 80px;
}

.certificate-description {
  padding-left: 50px;
  display: flex;
}

.certificate-description h3 {
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 700;
  color: white;
}

.certificate-description p {
  font-size: 13px;
  color: #12B2A7;
}

.certificate-description div {
  border-left: 1px solid blue;
  padding-left: 10px;
}










/* ================================
===       Service           =====
=================================== */













.active-tab {
  border-radius: 5px;
  box-shadow: 4px 4px 8px 2px rgba(0, 64, 255, 0.3);
}

.container-head-main {
  position: relative;
  font-family: "Montserrat", sans-serif;
}

.service-head {
  margin-top: 80px;
  text-align: center;
  justify-content: center;
}

.service-head h1 {
  font-size: 25px;
  font-weight: 700;
}

.cervice-parents nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
}

.Link-div {
  text-decoration: none;
}

.service-child {
  border: 1px solid rgb(198, 198, 198);
  height: 100px;
  width: 100%;
  border-radius: 5px;
  display: flex;
}

.service-child:hover {
  border: 1px solid rgb(198, 198, 198);
  height: 100px;
  width: 100%;
  color: black;
  border-radius: 5px;
  display: flex;
}

.service-child img {
  width: 80px;
}

.service-child-2 h3 {
  font-size: 20px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.service-child-2 h3:hover {
  font-size: 20px;
  color: black;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  transition: 0s;
}

.service-child-2 p {
  color: #727B8A;
  font-size: 16px;
}











/* ================================
===       Projects            =====
=================================== */




/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  height: 8px;
  /* Adjust the height of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
  /* Make the scrollbar thumb rounded */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}




.projects-head {
  font-family: "Montserrat", sans-serif;
  height: 850px;
  width: 100%;
  background-color: #e6e6e6;
}

.projects-head12 {
  align-items: center;
  text-align: center;
}

.project-child {
  margin: 0 auto;
  margin-top: 60px;
  height: 380px;
  width: auto;
  background-color: white;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.project-child-head {
  height: 50px;
  width: 100%;
  background-color: #131237;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.project-child-head span {
  font-size: 15px;
  color: #c1c1c1;
  font-weight: 700;
  margin-left: 0px;
  padding-left: 0px;
}


.img-head {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.img-head img {
  width: 60px;
}

.img-head h4 {
  font-weight: 700;
  font-size: 40px;
  margin-left: 20px;
  font-family: "Montserrat", sans-serif;
}

.cerifyby {
  margin-top: 70px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.sdfsafsd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cerifyby img {
  width: 150px;
  margin-left: 10px;
}

.cerifvyp {
  color: #0e0a1b;
  font-weight: 700;
}

.cerifvyp span {
  color: #0487FB;
  font-weight: 700;
}

.w3c {
  width: 80px;
}

.bigimages img {
  width: 220px;
}

.browserstack img {
  width: 200px;
}










/* ================================
===       mission             =====
=================================== */









.mission-head img {
  width: 250px;
  position: absolute;
}

.mission-parent {
  font-family: "Montserrat", sans-serif;
  margin-top: 100px;
  display: flex;
  position: relative;
}

.img-title-mission {
  position: relative;
  margin-left: 200px;
  margin-top: 50px;
  display: flex;
}

.mission-head-img {
  margin-left: 0px;
  width: 70%;
}

.mission-head-img img {
  width: 100%;
}

.mission-detils {
  height: auto;
  width: 100%;
  margin-top: 150px;
  margin-left: 50px;
}

.mission-detils h1 {
  font-size: 30px;
  font-weight: 700;
}

.mission-detils p {
  margin-top: 15px;
  color: #727B8A;
  font-weight: 500;
}

.mission-detils button {
  margin-top: 20px;
}















/* ================================
===       Workprove           =====
=================================== */












.marquee-div-w {
  display: flex;
}



.marquee-div-w {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.marquee-div-w img {
  border-radius: 5px;
  width: 200px;
  margin: 13px;
  transition: transform 0.4s ease;
}

.marquee-div-w img:hover {
  transform: scale(1.2);
  transition: transform 0.4s ease;
  animation-play-state: paused;
}












/* ================================
===       Testimonial           =====
=================================== */






.leftArrow1,
.rightArrow1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.leftArrow1 {
  left: 10px;
  /* Adjust as needed */
}

.rightArrow1 {
  right: 10px;
  /* Adjust as needed */
}





.Testimonials-head {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

/*   */

@media (max-width: 768px) {
  .Testimonials-head {
    flex-direction: column;
    align-items: center;
  }

  .e-card {
    width: 80%;
    margin-bottom: 20px;
  }
}

.e-card {
  margin-top: 30px;
  background: transparent;
  box-shadow: 0px 8px 28px -9px rgba(0, 0, 0, 0.45);
  position: relative;
  width: 240px;
  height: 330px;
  border-radius: 16px;
  overflow: hidden;
}

.wave {
  position: absolute;
  width: 540px;
  height: 700px;
  opacity: 0.6;
  left: 0;
  top: 0;
  margin-left: -50%;
  margin-top: -70%;
  background-color: var(--color-changer);
  /* background: linear-gradient(744deg, #af40ff, #5b42f3 60%, #00ddeb); */
}

.icon {
  width: 3em;
  margin-top: -1em;
  padding-bottom: 1em;
}

.infotop {
  font-size: 20px;
  left: 0;
  right: 0;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.infotop .infotop-img {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  border-radius: 50%;
  border: 3px solid rgb(236, 229, 229);
  position: relative;
  overflow: hidden;
}

.infotop .text-content {
  padding-top: 15px;
  text-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  line-height: 0.4;
  position: relative;
}

.infotop .detils-p {
  font-size: 10px;
  padding: 5px;
  position: absolute;
  text-align: justify;
  text-justify: inter-word;
  letter-spacing: 0.5px;
}

.infotop .end-section456 {
  height: 50px;
  width: 100%;
  /* background-color: #cccccc; */
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;

}

.infotop .end-section456 .FontAwesomeIcon123 {
  /* color: rgb(2, 2, 26); */
  margin: 5px;
}

.name {
  font-size: 14px;
  font-weight: 100;
  position: relative;
}

.wave:nth-child(2),
.wave:nth-child(3) {
  top: 210px;
}

.playing .wave {
  border-radius: 40%;
  animation: wave 3000ms infinite linear;
}

.wave {
  border-radius: 40%;
  animation: wave 55s infinite linear;
}

.playing .wave:nth-child(2) {
  animation-duration: 4000ms;
}

.wave:nth-child(2) {
  animation-duration: 50s;
}

.playing .wave:nth-child(3) {
  animation-duration: 30000ms;
}

.wave:nth-child(3) {
  animation-duration: 45s;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}













/* ================================
===       Mapchart           =====
=================================== */











#chartdiv {
  position: absolute;
}

















/* ================================
===          ceo               =====
=================================== */







.ceo {
  /* background-color: #0E0A1B; */
  height: 1600px;
  width: 100%;
  margin-top: 100px;
  position: absolute;
  background-color: var(--primary-color);
}

.img-div {
  margin-top: 90px;
}

.ceo-parent {
  border-radius: 5px;
  position: absolute;
  left: 50%;
  margin-top: 1680px;
  transform: translate(-50%, -50%);
  height: 230px;
  width: 400px;
  background-color: #1E1E1E;
  box-shadow: 2px 2px 2px 2px #333;
  display: flex;
}

.ceo-parent img {
  position: absolute;
}

.ceo-img {
  width: 30%;

}

.ceo-img img {
  width: 200px;
  border: 1px solid rgb(146, 144, 144);
  border-radius: 5px;
}

.ceo-detils {
  color: rgb(163, 165, 167);
  width: 100%;
}

.ceo-detils h3 {
  margin-top: 24px;
  color: white;
  font-size: 25px;
  font-family: "Montserrat", sans-serif;
}

.ceo-detils span {
  color: #0487FB;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
}

.ceo-detils p {
  margin-bottom: 0px;
  margin-top: 15px;
  color: #CCCCCC;
  font-size: 15px;
}

.ceo-detils button {
  margin-top: 10px;
  border-radius: 20px;
  padding: 5px;
  width: 200px;
  background-color: var(--color-changer);
  color: white;
}

.ceo-detils h5 {
  margin-top: 10px;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
}

.h3-span {
  line-height: 0.2;
}










/* ================================
===       Contact              =====
=================================== */








.error-message {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ffdddd;
  color: #d8000c;
  padding: 10px 20px;
  border: 1px solid #d8000c;
  border-radius: 5px;
  font-weight: bold;
  z-index: 1000;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.error-message.show {
  opacity: 1;
}




.icon-main {
  color: var(--color-changer);
}


.contact-headmain {
  margin-top: 1850px;
  position: relative;
  /* background-color: var(--primary-color); */
}

.footer-logo .icon {
  color: white;
}

.location-div img {
  width: 50px;
}

.contact-icon {
  background-color: var(--primary-color);
}

.contact-headmain .container .marquee-div-name .flex h1 {
  font-family: sans-serif;
  font-weight: 800;
}


.contact-form-container {
  border-radius: 5px;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;

}

.contact-form {
  display: flex;
  flex-direction: column;
}

.name-email input {
  color: #6a768e;
  font-size: 12px;
}

.phoneinput input {
  color: #6a768e;
  font-size: 12px;
}

input,
textarea {
  margin-bottom: 15px;
  background-color: #131237;
  padding: 10px;
  border-radius: 5px;
}

.text-area {
  height: 120px;
  max-height: 120px;
  min-height: 120px;
  color: #6a768e;
  font-size: 12px;
}

.contact-form-container .contact-form button {
  padding: 8px;
  border-radius: 5px;
  border: none;
  border: 2px solid var(--color-changer);
  background-color: #131237;
  color: white;
  cursor: pointer;
}

.contact-form-container .contact-form button:hover {
  padding: 8px;
  border-radius: 5px;
  border: none;
  border: 2px solid var(--color-changer);
  background-color: var(--color-changer);
  transition: 0.5s;
  color: white;
  cursor: pointer;
}

.how-can {
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 25px;
  color: white;
}

.contact-form button {
  width: 100%;
  height: 40px;
  color: white;
  /* background-color: #007bff; */
  margin-top: 20px;
  border-radius: 5px;
}

.phoneinput .phoneinput {
  width: 50%;
  background-color: wheat;
  border-radius: 5px;
}












/* ================================
===       Workprove           =====
=================================== */







/* ================================
===       What is  do            =====
=================================== */




.dev-design {
  background-color: #271f2c;


}



.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
   /* Adjust the scrollbar width */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f0f0f0;
  /* Scrollbar track color */
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #a0aec0;
  /* Scrollbar thumb color */
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #718096;
  /* Darker shade on hover */
}