.main-div {
    width: 10rem;
    height: 10rem;
    background-color: blanchedalmond;
    position: fixed;
    right: 0px;
    z-index: 999;
    transform: translate(100%, -50%);
    padding: 1rem;
    bottom: 100px;
    transition: 0.5s;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.main-div--open {
    transform: translate(0, -50%);
}

.main-div h3 {
    font-size: 1.2rem;
    margin-bottom: 1.2rem;
    text-align: center;
}

.main-div button {
    width: 3rem;
    height: 3rem;
    position: absolute;
    border: none;
    background-color: var(--color-changer);
    left: 0;
    top: 50%;
    transform: translate(-100%, -50%);
    color: red;
    font-size: 1.2rem;
    display: grid;
    place-items: center;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}

@keyframes app-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.color-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
}

.color-item {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: blue;
}