 .skills-main-head-div {
     position: relative;
 }

 .skills {
     height: 400px;
     width: 100%;
     margin-top: 400px;
     display: flex;
 }

 .skills-headline {
     padding-top: 15%;
     padding-left: 20px;
     padding-right: 25px;
     height: 100%;
     width: 100%;
 }

 .skills-headline h1 {
     font-size: 30px;
     font-weight: 700;
     font-family: "Montserrat", sans-serif;
 }

 .skills-headline p {
     font-weight: 500;
     color: #727B8A;
     font-size: 15px;
     font-family: "Montserrat", sans-serif;
 }

 .skills-headline .skills-button {
     margin-top: 50px;
 }

 .skills-img {
     display: grid;
     grid-template-columns: repeat(3, 1fr);
     height: 100%;
     width: 100%;
 }

 .skills .skills-img .js {
     margin-top: auto;
 }

 .skills .skills-img .js img {
     margin-left: auto;
     width: 150px;
     text-align: center;
 }

 .react {
     margin-top: auto;
 }

 .react img {
     margin-top: auto;
     margin-left: auto;
     width: 150px;
 }